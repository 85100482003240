.footer, .footer p {
  color: #000; /* テキスト色を黒に設定 */
  text-align: center;
}

.footer {
  background-image: url('../assets/footer_background.webp');
  background-size: cover;
  padding: 20px 0;
  font-size: 14px;
  line-height: 1.5;
}

.footer-content p {
  margin: 5px 0; /* 段落間の余白 */
}
