.container {
    padding: 2rem;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    margin: 2.5rem auto;
    max-width: 1200px;
  }
  .header {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1rem;
    list-style-type: none;
    padding: 0;
  }
  .listItem {
    display: flex;
    flexDirection: column;
    alignItems: center;
    textAlign: center;
  }
  .image {
    width: 100px;
    height: 100px;
    objectFit: cover;
  }
  