.App {
  text-align: center;
  background-color: white; /* Set background to white */
  position: relative; /* Needed for absolute positioning inside */
  overflow: hidden; /* Keeps the ribbon within the bounds of the App */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.section-text {
  color: #333; /* 暗色のテキストカラーを設定 */
  font-size: 16px; /* 適切なフォントサイズを設定 */
  line-height: 1.6; /* 読みやすい行間隔を設定 */
}

.section-container {
  background-color: #fff; /* 明確に背景色を白に設定 */
  padding: 20px;
  margin: 10px 0; /* 上下のマージンを設定 */
  border-radius: 8px; /* 角丸の設定 */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* 軽いシャドウを追加 */
}

.component-spacing {
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  height: 300px; /* 全ての要素に統一された高さを設定 */
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.text-content {
  background-color: rgba(0, 0, 0, 0.5); /* テキストを読みやすくするための背景 */
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  text-align: center;
}

@keyframes modalZoomIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes modalZoomOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
}

.modal-zoom-in {
  animation: modalZoomIn 0.5s ease-out forwards;
}

.modal-zoom-out {
  animation: modalZoomOut 0.5s ease-out forwards;
}

/* App.cssに追加 */
.blog-posts {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.post {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.post h2 {
  color: #333;
  margin-bottom: 10px;
}

.post p {
  color: #666;
  line-height: 1.5;
}
