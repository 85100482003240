.nurse-recruitment-container {
    background-size: cover;
    background-position: center;
    padding: 20px;
    color: white;
    cursor: pointer;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
}

.modal-content h2, .modal-content p {
    color: black;
}
