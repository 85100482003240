.care-facility-container {
    background-size: cover;
    background-position: center;
    padding: 20px;
    color: white;
  }
  
  .text-content {
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 8px;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 16px;
  }
  
  p, li {
    font-size: 16px;
    line-height: 1.5;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  