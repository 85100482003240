.patient-family-container {
    background-size: cover;
    background-position: center;
    padding: 20px;
    color: white;
  }
  
  .text-content {
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 8px;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 16px;
  }
  
  p, a {
    font-size: 16px;
    line-height: 1.5;
    color: white; /* リンクの色もここで調整 */
  }
  
  a {
    color: #61dafb; /* リンク色 */
    text-decoration: none; /* アンダーラインを消す */
  }
  