.contact-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: transparent;
    padding: 0;
  }
  
  .notepad-form {
    width: 100%;
    max-width: 500px;
    background-color: rgba(255, 255, 255, 0.5); /* 半透明の白色 */
    background-blend-mode: lighten; /* 背景色と画像を明るく混合 */
    background-image: 
        linear-gradient(#f1f1f1 1px, transparent 1px),
        linear-gradient(90deg, #f1f1f1 1px, transparent 1px);
    background-size: 20px 20px;
    background-image: url('../assets/contact.webp');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 30px;
    border-radius: 8px;
    box-shadow: none;
    border-top: 20px solid #f0a30a;
    position: relative;
  }
  
  .notepad-form::before {
    content: '';
    position: absolute;
    top: -20px;
    left: 20px;
    width: 70px;
    height: 35px;
    background-color: #e09200;
    clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
  }
  
  .form-header {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .form-header h2 {
    font-size: 24px;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    color: #666;
  }
  
  .form-group label, .form-header h2 {
    color: #000; /* ラベルとヘッダーのテキストを黒色に変更 */
  }

  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #000; /* 記入欄の縁を黒色に変更 */
    border-radius: 4px;
    font-size: 16px;
    background-color: transparent;
  }
  
  .form-group textarea {
    height: 120px;
    resize: vertical;
  }
  
  .submit-button {
    display: block;
    width: 100%;
    padding: 12px;
    color: #000; /* 送信ボタンのテキスト色を黒に変更 */
    background-color: #fff; /* 送信ボタンの背景色を白に変更 */
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #333;
  }
  
  .submit-button:disabled {
    background-color: #999;
    cursor: not-allowed;
  }